// @import 'leaflet/dist/leaflet.css';

// Custom Dashboard Css
.activity-wid .activity-list:last-child::before {
  display: none;
}

// owl-carousel
// @import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
// @import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

/**
* lightbox
*/
@import "~ngx-lightbox/lightbox.css";

// alert
.alert-dismissible {
  .btn-close {
    font-size: 10px;
    padding: $alert-padding-y * 1.4 $alert-padding-x;
    background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
  }
}

.custom-alert {
  .close {
    background: transparent;
    border: 0;
    font-size: 10px;
    padding: 1.35rem 1.25rem;
    background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.5;
    width: 1em;
    height: 1em;
    z-index: 2;
    color: $btn-close-color;

    span {
      display: none;
    }
  }
}

.vimeo_video iframe {
  width: 100%;
}

.vimeo_video #player {
  max-width: 100% !important;
}

/**
* Rating Module
*/
.stars {
  font-size: 1.5rem;
  color: #b0c4de;
}

.filled {
  color: #1e90ff;
}

.bad {
  color: #deb0b0;
}

.filled.bad {
  color: #ff1e1e;
}

.star {
  position: relative;
  display: inline-block;
  font-size: 1.5rem;
  color: #d3d3d3;
}

.full {
  color: #b0c4de;
}

.half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: #b0c4de;
}

// color picker
.color_picker {
  width: auto;
  margin: 0 auto;
  display: inline-block;
}

.color_picker .input-group-text {
  padding: 20px;
}

// Select2
@import "~@ng-select/ng-select/themes/default.theme.css";

// .multi_default .ng-value-icon {
//   display: none !important;
// }

// .ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label {
//   padding: 0 5px;
//   background-color: $gray-600;
//   border: 1px solid $gray-600;
//   padding: 4px 10px;
//   border-radius: 20px;
// }

// .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
//   background-color: transparent !important;
//   border:none !important;
//   color: $white;
// }

// Datepicker
.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.custom-day.focused {
  background-color: #e6e6e6;
}

.custom-day.range,
.custom-day:hover {
  background-color: rgb(2, 117, 216);
  color: white;
}

.custom-day.faded {
  background-color: rgba(2, 117, 216, 0.5);
}

/**
* Image Upload Dropzone
*/
@import "~dropzone/dist/min/dropzone.min.css";

// Custome css

.border_none {
  border: none;
}

// dark style

body[data-layout-mode="dark"] {

  // Calender
  .fc-theme-standard .fc-scrollgrid,
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: 1px solid $gray-300;
  }

  .fc .fc-daygrid-day-number,
  .fc .fc-col-header-cell-cushion {
    color: $white;
  }

  // Accordion
  .accordion {
    .card-header {
      padding: 5px;
      background-color: $secondary;
    }
  }

  // Advance Form
  // .ng-select .ng-select-container {
  //   background-color: lighten($gray-200, 2%);
  //   color: $gray-500;
  //   border-color: $gray-300;

  //   .ng-value-container .ng-input>input {
  //     color: $gray-500;
  //   }
  // }

  // .ng-dropdown-panel {
  //   background-color: lighten($gray-200, 2%) !important;
  //   border-color: $gray-300;

  //   .ng-dropdown-panel-items {
  //     .ng-option {
  //       background-color: lighten($gray-200, 2%) !important;
  //       color: $gray-500;

  //       &.ng-option-marked {
  //         background-color: lighten($gray-200, 4%) !important;
  //         color: $gray-600;
  //       }
  //     }

  //     .ng-optgroup {
  //       color: rgba($white, 0.4);
  //     }
  //   }
  // }

  .switch.checked {
    border-color: #000;
  }

  // File Upload
  .dropzone {
    background-color: #333836 !important;
    border-color: $gray-300 !important;
  }

  .dropzone .dz-message {
    background-color: #333836 !important;
  }
}

.dropzone .dz-message {
  font-size: 24px;
  min-height: 150px !important;
  border: none !important;
  background: #fff !important;
  color: #495057 !important;
}

// data table

.datatables {
  thead {
    tr {
      th {
        position: relative;

        &:before {
          content: "\F0143";
          position: absolute;
          right: 0;
          top: 7px;
          font: normal normal normal 24px/1 "Material Design Icons";
          opacity: 0.3;
        }

        &:after {
          content: "\F0140";
          position: absolute;
          right: 0;
          top: 14px;
          opacity: 0.3;
          font: normal normal normal 24px/1 "Material Design Icons";
        }
      }

      .asc {
        &:before {
          opacity: 1;
        }
      }

      .desc {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}


body[data-sidebar-size="md"] {

  #sidebar-menu ul li a {
    i-feather {
      display: block;
      margin: 0px auto;
    }
  }
}

body {
  * {
    outline: none;
  }
}

// flatpicker
@import '~flatpickr/dist/flatpickr.css';


.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: rgb(250, 70, 22);
  --bs-btn-border-color: rgb(250, 70, 22);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(180 44 7);
  --bs-btn-hover-border-color: rgb(180 44 7);
  --bs-btn-focus-shadow-rgb: none;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(250, 70, 22);
  --bs-btn-active-border-color: rgb(250, 70, 22);
  --bs-btn-active-shadow: none;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: rgb(250, 70, 22);
  --bs-btn-disabled-border-color: rgb(250, 70, 22);
}

.btn-soft-primary {
  color: rgb(250, 70, 22);
  background-color: #f9cdc1 !important;
}

.btn-soft-primary:hover, .btn-soft-primary:focus {
  color:  rgb(250, 70, 22) !important;
  background-color: #f9cdc1 !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.p-avatar {
  background-color: #f9cdc1 !important;
  border-radius: 3px;
}

//responsive
@media (max-width: 992px) {
  .responsive_search {
    padding-bottom: 10px;
  }

  .responsive_sidebar{
    width: 70% !important;
  }

  .responsive_sidebar_max {
    width: 100% !important;
  }
  .responsive_pagnation {
    display: flex !important;
  }
 }
