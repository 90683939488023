// :root CSS dark variables


[data-layout-mode="dark"]{

  // Color system - Dark Mode only
  $gray-100: #3b403d;
  $gray-200: #313533;
  $gray-300: #3b403d;
  $gray-400: #858d98;
  $gray-500: #adb5bd;
  $gray-600: #ced4da;
  $gray-700: #f6f6f6;
  $gray-800: #e9e9ef;
  $gray-900: #f8f9fa;

  $grays: (
      "100": $gray-100,
      "200": $gray-200,
      "300": $gray-300,
      "400": $gray-400,
      "500": $gray-500,
      "600": $gray-600,
      "700": $gray-700,
      "800": $gray-800,
      "900": $gray-900
  );

  // Prefix for :root CSS variables
  @each $color, $value in $grays {
      --#{$prefix}gray-#{$color}: #{$value};
  }

  // Vertical Sidebar  Light

  --#{$prefix}sidebar-bg: #2b2e3b;
  --#{$prefix}sidebar-menu-item-color: #898fa9;
  --#{$prefix}sidebar-menu-sub-item-color: #7b8198;
  --#{$prefix}sidebar-menu-item-icon-color: #666b7f;
  --#{$prefix}sidebar-menu-item-icon-effact-color: rgba(133,141,152,0.2);
  --#{$prefix}sidebar-menu-item-hover-color: #5b73e8;
  --#{$prefix}sidebar-menu-item-hover-effact-color: rgba(255,255,255,0.2);
  --#{$prefix}sidebar-menu-item-active-color: #5b73e8;
  --#{$prefix}sidebar-menu-item-active-effact-color: rgba(255,255,255,0.2);
  --#{$prefix}sidebar-border-color: #373c39;
  --#{$prefix}sidebar-menu-item-active-bg-color: #34415d;
  --#{$prefix}sidebar-menu-item-active-bg-color-dark: #343747;
  --#{$prefix}sidebar-alert: #313533;

 // Vertical Sidebar - Dark
 --#{$prefix}sidebar-dark-bg: #2C302E;
 --#{$prefix}sidebar-dark-menu-item-color: #99a4b1;
 --#{$prefix}sidebar-dark-menu-sub-item-color: #858d98;
 --#{$prefix}sidebar-dark-menu-item-icon-color: #858d98;
 --#{$prefix}sidebar-dark-menu-item-hover-color: #ffffff;
 --#{$prefix}sidebar-dark-menu-item-active-color: #ffffff;

  // Topbar - Deafult Light
  --#{$prefix}header-bg:                            #313533;
  --#{$prefix}header-item-color:                    #e9ecef;

    // Topbar Search
    --#{$prefix}topbar-search-bg:                   #363a38;
    --#{$prefix}topbar-dark-search-bg:              #363a38;
    --#{$prefix}search-width:                       1px;
    --#{$prefix}search-border-color:                #3b403d;

    // scss-docs-start box-shadow-variables
  --#{$prefix}box-shadow:                             0 0.25rem 0.75rem rgba($gray-300,.08);

  // Topbar - Dark
  #page-topbar{
    --#{$prefix}header-dark-bg:                       #313533;
    --#{$prefix}header-dark-item-color:               #e9ecef;
  }


  // Footer
  --#{$prefix}footer-bg:                              #{$gray-200};
  --#{$prefix}footer-color:                           #adb5bd;

    // Horizontal 
  --#{$prefix}topnav-bg:                              #2d313e;
  --#{$prefix}topnav-item-color:                      #{$gray-400};
  --#{$prefix}topnav-item-color-active:               #{$white};
  --#{$prefix}menu-item-color:                        #99a4b1;
  --#{$prefix}menu-item-active-color:                 #ffffff;

  // body
  --#{$prefix}body-bg :                                #{$gray-200};
  --#{$prefix}body-color:                              #{$gray-600};
  --#{$prefix}body-color-rgb:                          #{to-rgb($body-color)};
 
      // Display
    --#{$prefix}display-none:                          block;
    --#{$prefix}display-block:                         none;

    // card-title-desc
  --#{$prefix}card-title-desc:                         #{$gray-400};


  // Horizontal nav
  --#{$prefix}topnav-bg:                               #{lighten($gray-200, 2.5%)};
  --#{$prefix}topnav-item-color:                       #{$gray-400};
  --#{$prefix}topnav-item-color-active:                #{$white};

  // twocolumn menu
  --#{$prefix}twocolumn-menu-iconview-bg:              #{$white};
  --#{$prefix}twocolumn-menu-bg:                       #{$white};
    
  // two column dark
  --#{$prefix}twocolumn-menu-iconview-bg-dark:         var(--#{$prefix}vertical-menu-bg-dark);
  --#{$prefix}twocolumn-menu-bg-dark:                  #30363a;
  --#{$prefix}twocolumn-menu-item-color-dark:          var(--#{$prefix}vertical-menu-item-color-dark);
  --#{$prefix}twocolumn-menu-item-active-color-dark:   #{$white};
  --#{$prefix}twocolumn-menu-item-active-bg-dark:      #{rgba($white, .15)};

  // boxed 
  --#{$prefix}boxed-body-bg:                           #{darken($gray-100, 4%)};

  // heading-color
  --#{$prefix}heading-color:                           #{$gray-700};
  
  // component variable

  --#{$prefix}white:                                   #{$gray-200};
  --#{$prefix}light:                                   #{$gray-300};
  --#{$prefix}light-rgb:                               #{to-rgb($gray-300)};
  --#{$prefix}dark:                                    #{$gray-800};
  --#{$prefix}dark-rgb:                                #{to-rgb($gray-800)};
  --#{$prefix}text-muted:                              #{$gray-400};

  // link
  --#{$prefix}link-color:                              #{$gray-700};
  --#{$prefix}link-hover-color:                        #{$gray-700};

  // Border variable
  --#{$prefix}border-color:                            #{$gray-300};

  // dropdown
  .dropdown-menu{
    --#{$prefix}dropdown-bg:                            #{$gray-200};
    --#{$prefix}dropdown-link-color:                    #{$gray-500};
    --#{$prefix}dropdown-link-hover-color:              #{shade-color($gray-900, 5%)};
      --#{$prefix}dropdown-link-hover-bg:               #{$gray-300};
      --#{$prefix}dropdown-border-width:                1px;
  }
 
  .dropdown{
    --#{$prefix}btn-border-color:                       $body-color;
  }

  // card
  .card{
    --#{$prefix}card-bg:                                #{$gray-200};
    --#{$prefix}card-cap-bg:                            #{$gray-200};
    --#{$prefix}card-border-color:                      #3b403d;
  }

  --#{$prefix}card-bg:                                  #{$gray-200};
  --#{$prefix}card-logo-dark:                           none;
  --#{$prefix}card-logo-light:                          block;

  // modal
  .modal{
    --#{$prefix}modal-bg:                               #{$gray-200};
    --#{$prefix}modal-content-bg:                       #{$gray-200};
    --#{$prefix}modal-content-border-color:             #{$gray-300};  
    --#{$prefix}modal-header-border-color:              #{$gray-300};
    --#{$prefix}modal-footer-border-color:              #{$gray-300};
  }

  //offcanvas
  .offcanvas{
    --#{$prefix}offcanvas-bg-color:                     #{$gray-200};
  }

  // nav tabs
  .nav-tabs{
    --#{$prefix}nav-tabs-link-active-color:             #{$gray-900};
    --#{$prefix}nav-tabs-link-active-bg:                #{$gray-200};
    --#{$prefix}nav-tabs-link-active-border-color:      #{$gray-400} #{$gray-400} transparent;

  }


// accordion
.accordion{
  --#{$prefix}accordion-button-active-color:            #{$white};
  --#{$prefix}accordion-bg:                             #{$gray-200};
  --#{$prefix}accordion-button-bg:                      #{$gray-200};
  --#{$prefix}accordion-button-active-bg:               #{$gray-300};
  --#{$prefix}accordion-border-color:                   #{$border-color};
}

  // table
  .table{
  --#{$prefix}table-color:                              #{$gray-400};
  }
  // Boxed layout 
  --#{$prefix}boxed-body-bg:                            #3b403d;

  // progress
  .progress{
  --#{$prefix}progress-bg:                              #{$gray-300};
  }

  // toast
  .toast{
    --#{$prefix}toast-background-color:                 #{rgba($gray-300, .85)};
    --#{$prefix}toast-border-color:                     #{rgba($white, .1)};
    --#{$prefix}toast-header-border-color:              #{rgba($white, .05)};
  }


  //list
  .list-group{
    --#{$prefix}list-group-bg:                          #{gray-200};
  }

  --#{$prefix}list-group-hover-bg:                      #{lighten($gray-200, 2.5%)};

  // popover
  .popover{
  --#{$prefix}popover-bg:                               #{$gray-200};
  --#{$prefix}popover-border-color:                     #{$border-color};
  --#{$prefix}popover-body-color:                       #{$gray-400};
  --#{$prefix}popover-header-bg:                        #{shade-color($gray-200, 4%)};
  }

  // pagination
  .pagination{
  --#{$prefix}pagination-bg:                            #{$gray-200};
  --#{$prefix}pagination-hover-bg:                      #{lighten($gray-200, 4%)};
  --#{$prefix}pagination-disabled-bg:                   #{lighten($gray-200, 1%)};
  --#{$prefix}pagination-focus-bg:                      #{lighten($gray-200, 4%)};


  }

  //form
  --#{$prefix}input-bg:                                 #{lighten($gray-200, 2.5%)};
  --#{$prefix}input-group-addon-border-color:           #{$gray-300};
  --#{$prefix}input-border:                             #{$gray-300};
  --#{$prefix}input-border-color:                       #{$gray-300};
  --#{$prefix}input-focus-border:                       #{lighten($gray-300, 4%)};
  --#{$prefix}input-disabled-bg:                        #{$gray-200};

  // input-group-addon
  --#{$prefix}input-placeholder-color:                  #{$gray-400};
  --#{$prefix}input-group-addon-bg:                     #{$gray-300};

  //check
  --#{$prefix}input-check-border:                       #{lighten($gray-300, 4%)};

}